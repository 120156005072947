import { graphql, PageProps } from "gatsby"
import _ from "lodash"
import React from "react"

import { CollectionPageContext } from "../interfaces/pageContext"
import CollectionPage from "./CollectionPage"

export type LuxuryPageProps = PageProps<any, CollectionPageContext>

const LuxuryPage: React.FC<LuxuryPageProps> = (props) => {
  const { data } = props
  const navigation = [
    {
      heading: `Luxus Kategorien`,
      links: _.sortBy(
        data.categoryLuxuryPages.edges.map(({ node }: any) => ({
          to: node.path,
          text: `Luxus ${node.context.category}`,
        })),
        [(link): string => link.text.toLowerCase()]
      ),
    },
  ]
  return (
    <CollectionPage
      name={`Luxustaschen`}
      description={`Luxus Taschen online kaufen bei buybags.de. Entdecke jetzt luxuriöse weiße, braune und schwarze Designer Taschen von MCM, Chloé, DOLCE&GABBANA und Off-White.`}
      primaryKeyword={`Luxustaschen`}
      secondaryKeyword={`Designertaschen Online Shop`}
      navigation={navigation}
      {...props}
    />
  )
}

export default LuxuryPage

export const query = graphql`
  query LuxuryPageQuery($skip: Int!, $limit: Int!) {
    allProductsCsv(filter: { fields: { luxury: { eq: true } } }, skip: $skip, limit: $limit) {
      edges {
        node {
          fields {
            brand
            colors
            deepLink
            deliveryCost
            deliveryTime
            description
            gtin
            images {
              alt
              src
              title
            }
            material
            name
            price
            priceBefore
            sale
            slug
          }
        }
      }
      totalCount
    }
    categoryLuxuryPages: allSitePage(
      filter: { context: { humanPageNumber: { eq: 1 }, type: { eq: "CategoryLuxuryPage" } } }
      sort: { fields: [context___category], order: ASC }
    ) {
      edges {
        node {
          path
          context {
            category
          }
        }
      }
    }
  }
`
